import { createSlice } from '@reduxjs/toolkit';

const userNotifySlice = createSlice({
    name: "userNotify",
    initialState: {
        show:  false,
        message: null,
        type: null
    },
    reducers: {
        showNotify(state, action) {
            state.show = action.payload.show;
            state.message = action.payload.message;
            state.type = action.payload.type;
        }
    },
});

export default userNotifySlice;