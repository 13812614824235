// utility function that returns the projectIdentifier from localStorage

const getProjectIdentifier = () => {
  // if we have projectIdentifier in local storage, use it
  console.log("getProjectIdentifier!!");
  if (localStorage.getItem("projectIdentifier") !== null) {
    if (localStorage.getItem("projectIdentifier") !== "undefined") {
      return localStorage.getItem("projectIdentifier");
    }
  }
  return "490679d1-3a56-4dde-9ec2-483fbe600518";
};


export default getProjectIdentifier;
