import { useEffect } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import axiosRequest from "../axiosRequest";

const PrivateRoute = () => {
  const navigate = useNavigate()
  const loggedIn = localStorage.getItem("token");
  useEffect(() => {
    if (loggedIn) {
      console.log("loggedInloggedInloggedIn", loggedIn)
      // navigate("/timemanager")
      axiosRequest("login/validateToken", "POST")
    }
  }, [])    
  return loggedIn ? <Outlet /> : <Navigate to="/login" />;
};
export default PrivateRoute;