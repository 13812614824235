import { Fragment, lazy, Suspense, useEffect, useState } from 'react';
import useAxiosRequest from './CustomHooks/useAxiosRequest';
import { BrowserRouter, Link, Route, Routes, RouterProvider } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import fetchData from './axiosRequest';
import PrivateRoute from './routes/PrivateRoutes';
import MavryckLoader from './components/MavryckLoader/MavryckLoader';
import { generalLoaderActions } from './store';
import Chatbot from './components/VirtualAssistant/Chatbot';
import Chat from './components/Chat';
import ToastMessage from './components/UI/ToastMessage';
import { envVariables } from './config';

const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Signup = lazy(() => import("./pages/Signup"));
const ScenarioPlanning = lazy(() => import("./pages/ScenarioPlanning"));
const SingleScenario = lazy(() => import("./pages/SingleScenario"));
const TimeManager = lazy(() => import("./pages/TimeManager"));
const VisionAI = lazy(() => import("./pages/VisionAI"));
const AppScreen = lazy(() => import("./pages/AppScreen"));
const Vivclima = lazy(() => import("./pages/Vivclima"));
const CostManager = lazy(() => import("./pages/CostManager"));
const ProjectManager = lazy(() => import("./pages/ProjectManager"));
const EstimationApp = lazy(() => import("./pages/EstimationApp"));
const ResourceOptimizer = lazy(() => import("./pages/ResourceOptimizer"));
const RiskManagement = lazy(() => import("./pages/RiskManagement"));
const Alliance = lazy(() => import("./pages/Alliance"));
// const Gulermak = lazy(() => import("./pages/Gulermak"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const DevBuild = lazy(() => import("./components/DevBuild/DevBuild"));

function App() {
  const virtualAssistant = useSelector(state => state.virtualAssistant.visible);
  const chatVisible = useSelector(state => state.chat.visible);
  const notify = useSelector(state => state.userNotify);

  const basename = envVariables.getBasename() ?? "/"
  // const token = localStorage.getItem("token")
  // const validateToken = useAxiosRequest("login/validateToken", "POST");

  // useEffect(() => {
  //   if (token) {
  //     fetchData("login/validateToken", "POST")
  //   }
  // }, [token])

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("error");

  const toggleToast = (message = "", type = "error") => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(showToast ? false : true);
  };


  useEffect(() => {
    if (notify.show) {
      toggleToast(notify.message, notify.type)
    }
  }, [notify])

  // if (token) {

  //   console.log("validateToken", validateToken)
  // }

  return (
    <Fragment>
      {/* <RouterProvider router={router} fallbackElement={<div className='main-loader'><img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Mavryck" /></div>} /> */}
      <BrowserRouter basename={basename}> {/* ["/", "/dev", , "/demo"] */}
        <Suspense fallback={<div className='main-loader'><img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Mavryck" /></div>}>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route index element={<AppScreen />} />
              <Route path="/scenarioplanning" element={<ScenarioPlanning />} />
              <Route path="/activities" element={<SingleScenario />} />
              <Route path="/timemanager" element={<TimeManager />} />
              <Route path="/saif" element={<VisionAI />} />
              <Route path="/Vivclima" element={<Vivclima />} />
              <Route path="/FinancialManager" element={<CostManager />} />
              <Route path="/ProjectManager" element={<ProjectManager />} />
              <Route path="/estimation" element={<EstimationApp />} />
              <Route path="/ResourceOptimizer" element={<ResourceOptimizer />} />
              <Route path="/riskmanager" element={<RiskManagement />} />
              <Route path="/alliance" element={<Alliance />} />
            </Route>
            <Route path="/">
              {/* <Route path="/timemanager" element={<TimeManager />} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/dev-build/:prNumber" element={<DevBuild />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      {
        virtualAssistant ?
          <Chatbot />
          : null
      }
      {
        chatVisible ?
          <Chat />
          : null
      }
      {<ToastMessage message={toastMessage} type={toastType} onClose={() => toggleToast()} />}
    </Fragment>
  );
}


export default App;
