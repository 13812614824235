import store, { userNotifyActions } from "../store";

const sessionMiddleware = (props) => {
    store.dispatch(userNotifyActions.showNotify({show: true, message: "Session Expired! Login Again.", type: "Warning"}))
    setTimeout(() => {
        localStorage.clear();
        window.location.reload();
    }, 2000);
};

export default sessionMiddleware;